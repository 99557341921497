<template>
	<NuxtLayout name="one-column-layout">
		<template #title></template>

		<template #subtitle> </template>

		<template #contentSlot1>
			<StepperWidget />
		</template>

		<template #contentSlot2> </template>
	</NuxtLayout>
</template>

<script setup>
	const runtimeConfig = useRuntimeConfig()
	const firstPage = runtimeConfig.public.PAGES[0]

	//Routing away from this page when this component is mounted allows Vite to build this page. Otherwise, the index.html file is not generated.
	onMounted(() => {
		navigateTo({
			path: firstPage.urlPath,
		})
	})
</script>
